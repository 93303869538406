import { DOCUMENT } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, retry, shareReplay } from 'rxjs/operators';

import { PartnerRecordDto, PartnersApiService } from '@api-open';

import { NavigationPalette, NavigationLogos, NavigationCustomization } from './navigation-customization.interface';
export const defaultColors = {
  bg: '#22154D',
  itemText: '#FFFFFFFF',
  itemBg: '#FFFFFF00',
  itemBgHover: '#7D58F74D',
  itemTextActive: '#FFFFFFFF',
  itemBgActive: '#7D58F74D',
  itemBgActiveHover: '#7D58F7B2',
  bgCollapsedSubItem: '#22154DFF',
} as const;

@Injectable({
  providedIn: 'root',
})
export class CustomizationService {
  readonly partnerInfo$: Observable<PartnerRecordDto | null>;
  readonly navCustomization$: Observable<NavigationCustomization>;

  private readonly partnersApiService = inject(PartnersApiService);
  private readonly document = inject(DOCUMENT);

  constructor() {
    this.partnerInfo$ = this.partnersApiService
      .partnerInfo({ domain: this.document.location.host })
      .pipe(retry(1), shareReplay(1));

    // TODO: When strictNullChecks is enabled, replace non-null assertions with proper null checks
    this.navCustomization$ = this.partnerInfo$.pipe(map((partner) => this.getNavCustomization(partner!)));
  }

  private getNavCustomization(partnerInfo: PartnerRecordDto): NavigationCustomization {
    return {
      palette: this.getNavPalette(partnerInfo),
      logo: this.getNavLogos(partnerInfo),
    };
  }

  private getNavPalette(_partnerInfo: PartnerRecordDto): NavigationPalette {
    const {
      bg = '',
      bgCollapsedSubItem = '',
      itemText = '',
      itemBg = '',
      itemBgHover = '',
      itemTextActive = '',
      itemBgActive = '',
      itemBgActiveHover = '',
    } = _partnerInfo.partnerColorSettings ?? {};

    return {
      // TODO: When strictNullChecks is enabled, replace non-null assertions with proper null checks
      bg: bg === defaultColors.bg ? 'linear-gradient( 180deg, #22154D 0%, #37227B 100%)' : bg!,
      bgCollapsedSubItem: bgCollapsedSubItem!,
      itemText: itemText!,
      itemBg: itemBg!,
      itemBgHover: itemBgHover!,
      itemTextActive: itemTextActive!,
      itemBgActive: itemBgActive!,
      itemBgActiveHover: itemBgActiveHover!,
    };
  }

  private getNavLogos(_partnerInfo: PartnerRecordDto): NavigationLogos {
    const { collapsed, expanded } = _partnerInfo.partnerImageSettings ?? {};

    return {
      // TODO: When strictNullChecks is enabled, replace non-null assertions with proper null checks
      collapsed: collapsed!,
      expanded: expanded!,
    };
  }
}
